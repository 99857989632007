
import {onMounted} from 'vue'
import {mapActions, useStore} from 'vuex'
import router from "@/router"
import Dialog from "@/components/Dialog.vue"
import axios from "axios"

export default {
	name: "Save",
	components: { Dialog },
	setup() {
		const store = useStore()

		const ripaOfficerId = store.getters["session/getRandomId"]
		const originatingAgencyIdentifier = store.getters["session/getOriginatingAgencyIdentifier"]
		const formId = store.getters["form/getFormId"]
		const assignmentName = store.getters["profile/getAssignmentName"]
		const otherAssignment = store.getters["profile/getOtherAssignment"]
		const experience = store.getters["profile/getExperience"]
		const formRecord = store.getters["form_data/getFormRecord"]

		onMounted(() => {
			store.dispatch("dialog/openDialog")

			if (store.getters["form_data/getActiveFormRecordStatus"] === "Saved") {
				store.dispatch("dialog/setMessage", "Already Saved")

				setTimeout((path) => {
					store.dispatch("dialog/closeDialog")
					router.push("/start")
				}, 1500)

				return
			}

			store.dispatch("submission/create", {
				formId,
				originatingAgencyIdentifier,
				ripaOfficerId,
				"submission": {
					"assignment": assignmentName,
					"experience": experience,
					"other_assignment": otherAssignment,
					"form_data": formRecord
				}
			}).then((response) => {
				store.dispatch("dialog/setMessage", "Save Complete")

				store.dispatch("form_data/setItemValue", {
					"store":"",
					"key":"status",
					"value":"Saved"
				})

				setTimeout((path) => {
					store.dispatch("dialog/closeDialog")
					router.push("/home")
				}, 1500)
			}).catch((error) => {
				console.log(error)

				store.dispatch("dialog/setMessage", "Save Failed")

				setTimeout((path) => {
					store.dispatch("dialog/closeDialog")
					router.push("/review")
				}, 1500)
			})
		})

		// store.dispatch("session/renew")

		return {}
	},
	methods: {
		...mapActions("dialog", ["openDialog", "closeDialog",
			"setIcon", "setIconType", "setMessage",
			"setButtonLeftText", "setButtonLeftFunc",
			"setButtonRightText", "setButtonRightFunc",
			"setButtonXText", "setButtonXFunc"])
	},
	mounted() {
		this.setMessage("Saving...")
		this.setIconType("has-text-info")
		this.setIcon("fa-cloud-upload")
		this.setButtonLeftFunc(null)
		this.setButtonRightFunc(null)
		this.setButtonXText("X")
		this.setButtonXFunc(null)
		this.openDialog()
	}
}
